<template>
  <v-card>
    <v-card-text v-if="loading" class="pa-12 text-center body-2">
      <v-progress-linear :indeterminate="true" color="color3"></v-progress-linear>
      Please be patient while we load your theme.
    </v-card-text>
    <template v-else>
      <v-toolbar :color="previewTheme.color1">
        <v-app-bar-nav-icon>
          <v-btn :color="previewTheme.color1Text" icon @click.stop="$emit('toggle-drawer')">
            <v-icon>far fa-bars</v-icon>
          </v-btn>
        </v-app-bar-nav-icon>
        <img v-if="previewTheme.logoUrl" :src="previewTheme.logoUrl" alt="logo" style="height: 100%; max-height: 100%; position: relative">
        <v-toolbar-title v-else
          :style="`${$vuetify.breakpoint.smAndUp ? 'width: 300px; min-width: 250px;' : 'min-width: 72px;'} color:  ${previewTheme.color1Text}`"
          :color="previewTheme.color1Text"
          class="ml-0 pl-4"
        >
          {{previewTheme.name}}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="rows"
          hide-default-footer
        >
          <template v-slot:[`item.sanctionedBy`]="{item}">
            <img class="table-logo" :src="item.logo" :alt="item.logo" :key="item.logo" :style="{'background-color': previewTheme.color1}">
          </template>
          <template v-slot:[`item.startDate`]="{item}">
              <div>{{ item.dayDisplay }}</div>
              <div>{{ item.dateDisplay }}</div>
          </template>
          <template v-slot:[`item.name`]="{item}">
              <div class="font-weight-bold">{{ item.name }}</div>
              <div class="font-weight-thin">{{ item.divisions | formatArray }}</div>
          </template>
        </v-data-table>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import * as actions from '../../store/ActionTypes'

export default {
  props: ['username', 'logo'],
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['theme', 'getTheme', 'themes']),
    orgTheme () {
      return this.getTheme(this.username)
    },
    orgTheme2 () {
      return this.username && this.themes && this.themes.find(f => f.username === this.username)
    },
    previewTheme () {
      var selectedLogo = this.logo ? { logoUrl: this.logo.url } : {}
      return Object.assign({}, this.orgTheme || this.theme, selectedLogo)
    },
    headers () {
      return [
        { value: 'sanctionedBy', align: 'left', sortable: false },
        { text: 'Date', value: 'startDate', align: 'left', sortable: false },
        { text: 'Name', value: 'name', align: 'left', sortable: false }
      ]
    },
    rows () {
      return [
        {
          logo: this.previewTheme.logoUrl,
          dateDisplay: moment().format('MMM Do'),
          dayDisplay: moment().format('dddd'),
          name: 'Logo Preview Tournament'
        },
        {
          logo: this.previewTheme.logoUrl,
          dateDisplay: moment().format('MMM Do'),
          dayDisplay: moment().format('dddd'),
          name: 'Logo Preview Tournament'
        },
        {
          logo: this.previewTheme.logoUrl,
          dateDisplay: moment().format('MMM Do'),
          dayDisplay: moment().format('dddd'),
          name: 'Logo Preview Tournament'
        }
      ]
    }
  },
  mounted () {
    if (!this.orgTheme) {
      this.loading = true
      this.$store.dispatch(actions.LOAD_THEME, this.username)
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style>

</style>
