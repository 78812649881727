<template>
    <v-tabs
      v-model="tab"
      background-color="color3Text"
      fixed
      slider-color="color3"
    >
      <v-tab :key="1" class="color3--text">
        Logo
      </v-tab>
      <v-tab :key="2" class="color3--text">
        Library
      </v-tab>

      <v-tabs-items :touchless="tab === 1" v-model="tab">
        <v-tab-item :key="1">
          <logo-selector :username="username"/>
        </v-tab-item>
        <v-tab-item :key="2">
          <image-library
            :username="username"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
</template>

<script>
import { mapGetters } from 'vuex'
import ImageLibrary from '@/components/Organization/OrganizationImageLibrary.vue'
import LogoSelector from '@/components/Organization/LogoSelector.vue'

export default {
  props: ['username'],
  data () {
    return {
      tab: null
    }
  },
  computed: {
    ...mapGetters(['user']),
    organizationId () {
      const page = this.user.pages.find(f => f.username === this.username)
      return page && page.id
    }
  },
  methods: {
  },
  watch: {
  },
  mounted () {
  },
  components: {
    ImageLibrary,
    LogoSelector
  }
}
</script>

<style>
</style>
