<template>
  <v-card flat>
    <v-container fluid>
      <v-row dense>
        <v-col cols="12">
          <v-alert type="info" :value="true" dense>
            <span v-if="!logoToPreview">To preview a new logo select an image from below or upload a new one</span>
            <span v-else>To save this new logo click the save buttom on the bottom right of the screen</span>
          </v-alert>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <image-library
            :username="username"
            ref="library"
            noPreview
            @preview-click="onPreviewClick"
            singleRow
            :imgWidth="192"
            :imgHeight="64"
            :disabled="saving"
            :exclude="currentLogo"
            :images="images"
          />
        </v-col>
        <v-col cols="12">
          <logo-preview :logo="logoToPreview" :username="username"></logo-preview>
        </v-col>
      </v-row>
    </v-container>

    <v-fab-transition>
      <v-btn
        color="error white--text"
        fab
        fixed
        bottom right
        v-if="logoToPreview && !saving"
        @click.stop="logoToPreview = null"
        style="margin-right: 60px"
      >
        <v-icon>fas fa-times</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-fab-transition>
      <v-btn
      color="color3 color3Text--text"
        fab
        fixed
        bottom right
        v-if="logoToPreview"
        @click.stop="save"
        :loading="saving"
      >
        <v-icon>fas fa-save</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-card>
</template>

<script>
import ImageLibrary from '@/components/Organization/OrganizationImageLibrary.vue'
import LogoPreview from '@/components/Organization/LogoPreview.vue'
import * as actions from '@/store/ActionTypes'
import { mapGetters } from 'vuex'

export default {
  props: ['username', 'images'],
  data () {
    return {
      logoToPreview: null,
      saving: false
    }
  },
  computed: {
    ...mapGetters(['getTheme']),
    organizationId () {
      const page = this.$store.getters.user.pages.find(f => f.username === this.username)
      return page && page.id
    },
    currentLogo () {
      const theme = this.getTheme(this.username)
      return theme && theme.logoUrl
    }
  },
  methods: {
    onPreviewClick (image) {
      this.logoToPreview = image
    },
    onUploadClick () {
      this.$refs.library.onUploadClick()
    },
    save () {
      if (this.logoToPreview) {
        this.saving = true
        this.$VBL.post.logo(this.logoToPreview.id, this.organizationId)
          .then(response => {
            this.$store.dispatch(actions.UPDATE_THEME, { username: this.username, logoUrl: this.logoToPreview.url })
            this.logoToPreview = null
          })
          .catch(err => console.log(err.response))
          .finally(() => {
            this.saving = false
          })
      }
    }
  },
  components: {
    ImageLibrary,
    LogoPreview
  }
}
</script>

<style>

</style>
